/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 1px solid transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.error {
  color: red;
  font-size: 13px;
}

.tablex {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.tablex td,
.tablex th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}

.tablex td {
  width: 50%;
}

.tablex td,
.tablex th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}

.tablex thead {
  font-weight: bold;
}

.table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

.table td,
.table th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}

.table td {
  width: 50%;
}


.table td,
.table th {
  padding: 5px 10px;
  margin: 0;
  border: 1px solid #ddd;
}

.table td {
  width: 50%;
}

.table thead {
  font-weight: bold;
}

.table tr:hover {
  cursor: pointer;
}